import React from 'react';
import { FAQ } from 'src/templates';
import {Box, Container, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/styles';
import { Link } from "gatsby"
import iconoCavaliFactrack from "src/assets/svg/ayuda/icono-cavali-factrack.svg"

export default function CavaliYFactrack() {
 const theme = useTheme();
 const sm = useMediaQuery(theme.breakpoints.down('md'));
 const preguntas = [
  {
   titulo: "¿Qué es CAVALI?",
   contenidos: [
    {
     texto: "CAVALI es el Registro Central de Valores y Liquidaciones, una sociedad anónima encargada de la creación, mantenimiento y desarrollo de la infraestructura del mercado de valores del Perú.",
     tipo: "TEXTO",
     finParrafo: true,
    },
    {
     texto: "",
     tipo: "TEXTO",
     finParrafo: true,
    },
    {
     texto: "Entre sus diferentes funciones, CAVALI es el Registro Centralizado de Facturas Negociables. A través de su plataforma Factrack, permite que entidades de financiamiento registren las facturas pendientes de pago que las empresas estén dispuestas a transferir para que obtengan mérito ejecutivo.",
     tipo: "TEXTO",
     finParrafo: true,
    },
   ],
  },
  {
   titulo: "¿Qué es Factrack?",
   contenidos: [
    {
     texto: "Es la plataforma de CAVALI que permite que empresas como Cumplo realicen la constitución y registro de facturas negociables electrónicas. Factrack se encarga de validar con SUNAT la autenticidad de las facturas, para luego darles mérito ejecutivo y se registren en CAVALI.",
     tipo: "TEXTO",
     finParrafo: true,
    },
   ],
  },
  {
   titulo: "¿Cómo puedo registrarme como adquirente en Cavali?",
   contenidos: [
    {
     texto: "1. Ingresa al sitio de ",
     tipo: "TEXTO",
    },
    {
     texto: "autenticación de Cavali",
     tipo: "TEXTO",
     finParrafo: true,
     enlace: "https://facturas.cavali.com.pe/CavaliFN",
    },
    {
     texto: "2. Ingresa los datos de tu Clave SOL- SUNAT.",
     tipo: "TEXTO",
     finParrafo: true,
    },
    {
     texto: "3. Registra el correo electrónico e información de la o las personas de tu empresa que tendrán acceso al sistema.",
     tipo: "TEXTO",
     finParrafo: true,
    },
    {texto: '', tipo: 'TEXTO', finParrafo: true},
    {texto: 'Se recomienda registrar al menos a dos personas y mantener actualizados sus datos de contacto para tener una comunicación fluida.',
     tipo: 'TEXTO',
     finParrafo: true},
    
   ],
  },
 ]

 const Cabecera = () => {
  return <Box sx={{
   display: "flex",
   flexDirection: "row",
   alignItems: "flex-end",
   justifyContent: "center",
   color: 'text.secondary',
   minHeight: sm ? '92px' : '99px',
   height: '100%',
   padding: "36px 23px"
  }}/>
 };

 return (
  <Box sx={{ background: `${theme.palette.primary.main}20` }}>
   <Cabecera/>
   <Container>
    <Box sx={{ maxWidth: "1100px", margin: "0 auto"}}>
     <Box sx={{ padding: "72px 0"}}>
      <Box sx={{ marginBottom: "32px"}}>
       <Typography variant="h3" color="primary.dark" fontWeight={500}>Preguntas frecuentes</Typography>
      </Box>
      <Box sx={{ mt: 8, mb: 6}}>
       <Link to="/ayuda/" style={{ textDecoration: "none" }}>
        <Typography display="inline" color="secondary.main" sx={{fontWeight: 700}}>Preguntas frecuentes</Typography>
       </Link>
       <Typography display="inline" sx={{ ml: 2, mr: 2}}>{`>`}</Typography>
       <Typography display="inline" color="text.disabled">Cavali y Factrack</Typography>
      </Box>

      <FAQ 
       titulo="Cavali y Factrack"
       descripcion="Preguntas sobre el Registro Centralizado de Facturas Negociables."
       icono={iconoCavaliFactrack}
       preguntas={preguntas}
      />

     </Box>
    </Box>
   </Container>
  </Box>
 );
};
